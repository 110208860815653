import Vue from 'vue';
import Router from 'vue-router';
import MainView from './views/MainView';
import SleepView from './views/SleepView';

// import SHListView from './views/SHListView';
// import SHDetailView from './views/SHDetailView';

import SCListView from './views/SCListView';
import SCDetailView from './views/SCDetailView';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'MainView',
      component: MainView,
    },
    {
      path: '/sleep',
      name: 'SleepView',
      component: SleepView,
    },
    // {
    //   path: '/sh/list',
    //   name: 'SHListView',
    //   component: SHListView,
    // },
    // {
    //   path: '/sh/detail',
    //   name: 'SHDetailView',
    //   component: SHDetailView,
    // },

    {
      path: '/sc/list',
      name: 'SCListView',
      component: SCListView,
    },
    {
      path: '/sc/detail',
      name: 'SCDetailView',
      component: SCDetailView,
    },
  ],
});
