<template>
    <div class="body">

      <div class="container">

        <loading :active.sync="showLoading"
                :can-cancel="false"
                :is-full-page="true"></loading>

        <div v-if="isLoaded">
          <div class="player" v-bind:style="{ backgroundImage: 'url(' + lesson.sharedImage + ')' }">
            <div class="dim">
            </div>
            <div class="circlr_wrap">
              <div v-if="isPlaying" class="circle_position">
                <img class="circle" @click="play" src="@/assets/img_pause.png">
              </div>
              <div v-else class="circle_position">
                <img class="circle" @click="play" src="@/assets/img_play.png">
              </div>
            </div>
          </div>
        </div>

        <div v-if="isLoaded">
          <div class="margin-60"></div>
          <div class="lesson_name">{{ lesson.name }}</div>
          <div class="margin-30"></div>
          <div class="teacher_name">{{ teacher_name }}</div>
          <div class="margin-60"></div>
        </div>

        <div v-if="isLoaded">
          <audio ref="player" loop="false" autoplay @canplay="mountedPlayer" playsinline>
            <source
              :src="lesson.media"
              type="audio/mpeg">
          </audio>
        </div>

        <div v-if="loadPlayer" class="slider_wrap">
          <vue-slider
            v-model="value"
            @drag-start="dragStartSlider"
            @drag-end="dragEndSlider"
            :tooltip="'none'"></vue-slider>
        </div>

        <div v-if="loadPlayer" class="duration">
          <div>
            {{ durationPrint($refs.player.duration) }}
          </div>
          <div>
            {{ durationPrint(seekToTime(this.value / 100)) }}
          </div>
        </div>
      </div>
      <!-- <div style="margin: 100px">
        {{ lesson }}
      </div> -->
    </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: 'MainView',
    components: {
      VueSlider,
      Loading
    },
    computed: {
      showLoading () {
        return !this.isPlayerMounted
      },
      isLoaded () {
        return !!this.lesson
      },
      loadPlayer () {
        return this.isPlayerMounted
      },
      isPlaying () {
        return this.playing
      },
      teacher_name () {
        return "" // temporary
        // return this.lesson ? `저자: ${this.lesson.writerName} / 성우: ${this.lesson.readerName}` : ''
      }
    },
    async created() {
      var options = {
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8',
              'Authorization': "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyRGF0YSI6eyJpZCI6NjEsInN0YXR1cyI6MCwiZW1haWwiOiJ5b25nc2FuZy4yQGtva2tpcmkua3IiLCJmdWxsbmFtZSI6IndlYi1wbGF5ZXIifSwiaWF0IjoxNjA3MDgxOTkwfQ.UPjuNX_TQB0hSiY8NWSbeb-B11K506YTOrixHz8e7jo"
          },
          method: "POST",
          // url: "https://api-production.kokkiri.kr/" + "api/lesson/getDetails",
          url: "https://api-production.kokkiri.kr/" + "admin/api/lesson/getDetails",
          data: {}
      };

      // console.log(this.$route.query)
      let idx = this.$route.query.idx
      let id = this.contents[idx] ?? this.contents[1]

      options.data = {id};
      // console.log(options.data)

      let result = await this.axios(options);
      // console.log(result)
      this.lesson = result.data.data
      console.log(this.lesson)

      this.updateMediaMetadata()
    },
    async mounted() {
    },
    data() {
      return {
        contents: {
          1: 630,
          2: 631,
          3: 629,
        },
        audio: null,
        lesson: null,
        value: 0,     // 0~100
        playing: false,
        setWatch: false,
        isPlayerMounted: false,
        dragStart: false
      }
    },
    methods: {
      _play() {
        console.log('_play');
        if (this.playing == false) {
          console.log('_play play');
          this.$refs.player.play()
        } else {
          console.log('_play pause');
          this.$refs.player.pause()
        }

        this.playing = !this.playing

        this.updatePositionState()
      },
      play() {
        // console.log('play');
        if (this.$refs.player) {
          this._play()
        }
      },
      timeToSeek(curr) {
        // 0 ~ 1
        return (curr / this.$refs.player.duration)
      },
      seekToTime(value) {
        return value * this.$refs.player.duration
      },
      mountedPlayer() {
        console.log('mountedPlayer')
        this.isPlayerMounted = true;

        if (this.$refs.player) {
          this.$refs.player.addEventListener('timeupdate', () => {
            if (this.$refs.player.currentTime && this.dragStart == false) {
              this.value = this.timeToSeek(this.$refs.player.currentTime) * 100
              this.updatePositionState();
            }
          });
          this.$refs.player.addEventListener('pause', () => { this.playing = false; });
          this.$refs.player.addEventListener('play', () => { this.playing = true; });
        }
      },
      dragStartSlider() {
        this.dragStart = true;
      },
      dragEndSlider() {
        // console.log('here', v)
        if (this.$refs.player) {
          this.$refs.player.currentTime = this.seekToTime(this.value / 100)
        }
        this.dragStart = false
      },
      durationPrint (duration) {
          var sec_num = parseInt(duration, 10); // don't forget the second param
          var hours   = Math.floor(sec_num / 3600);
          var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
          var seconds = sec_num - (hours * 3600) - (minutes * 60);

          if (hours   < 10) {hours   = "0"+hours;}
          if (minutes < 10) {minutes = "0"+minutes;}
          if (seconds < 10) {seconds = "0"+seconds;}

          return minutes+':'+seconds;
      },
      updateMediaMetadata() {
        if ('mediaSession' in navigator) {
          let metadata = {
            title: this.lesson.name,
            artist: `저자: ${this.lesson.writerName} / 성우: ${this.lesson.readerName}`,
            album: this.lesson.courseName,
            artwork: [
               '96x96',
               '128x128',
               '192x192',
               '256x256',
               '384x384',
               '512x512',
             ].map((size) => ({
               src: this.lesson.sharedImage,
               sizes: size,
               type: 'image/png',
             }))
          }
          // console.log('mediaSession', metadata);
          navigator.mediaSession.metadata = new window.MediaMetadata(metadata);

          const defaultSkipTime = 10;
          navigator.mediaSession.setActionHandler('play', () => {
            if (this.$refs.player) {
              this._play()
            }
          });
          navigator.mediaSession.setActionHandler('pause', () => {
            if (this.$refs.player) {
              this._play()
            }
          });
          navigator.mediaSession.setActionHandler('stop', function() { /* Code excerpted. */ });
          navigator.mediaSession.setActionHandler('seekbackward', (details) => {
            const skipTime = details.seekOffset || defaultSkipTime;
            let currentTime = this.$refs.player.currentTime
            currentTime = Math.max(currentTime - skipTime, 0);
            this.$refs.player.currentTime = currentTime
            this.updatePositionState()
          });
          navigator.mediaSession.setActionHandler('seekforward', (details) => {
            const skipTime = details.seekOffset || defaultSkipTime;
            let currentTime = this.$refs.player.currentTime
            currentTime = Math.min(currentTime + skipTime, this.$refs.player.duration);
            this.$refs.player.currentTime = currentTime
            this.updatePositionState()
          });
          navigator.mediaSession.setActionHandler('seekto', (seek) => {
            if (this.$refs.player) {
              this.$refs.player.currentTime = this.seekToTime(seek)
              this.updatePositionState()
            }
          });
          // navigator.mediaSession.setActionHandler('previoustrack', function() { /* Code excerpted. */ });
          // navigator.mediaSession.setActionHandler('nexttrack', function() { /* Code excerpted. */ });
          // navigator.mediaSession.setActionHandler('skipad', function() { /* Code excerpted. */ });
        }
      },
      updatePositionState() {
        if (this.$refs.player) {
          if ("setPositionState" in navigator.mediaSession) {
            navigator.mediaSession.setPositionState({
              duration: this.$refs.player.duration,
              playbackRate: this.$refs.player.playbackRate,
              position: this.$refs.player.currentTime
            });
          }
        }
      }
    },
}
</script>

<style>
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
body {
  font-family: "Noto Sans KR", sans-serif;
  margin: 0px;
  padding: 0px;
}

.body {
  text-align: -webkit-center;
}
.container {
  max-width: 960px;
  text-align: center;
}

.dim {
  background-color: black;
  width: 100%;
  padding-top: 80%;
  position: absolute;
  top: 0px;
  opacity: 50%;
}

.circlr_wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
}
.circle_position {
  transform: translate(-50%, -50%);
}

.circle {
  width: 120px;
}
.player {
  width: 100%;
  padding-top: 80%;
  position: relative;
  background-size: cover;
}
.player_bg {
  filter: brightness(50%);
  width: 100%;
  max-height: 100%;
  /* width: 100%;
  padding-top: 100%; */
  object-position: center;
  object-fit: none;
}
.lesson_name {
  color: #222222;
  font-size: 24px;
  font-weight: 500;
}
.teacher_name {
  color: #222222;
  font-size: 22px;
  font-weight: 300;
}
.margin-60 {
  height: 3.75rem;
}
.margin-30 {
  height: 0.625rem;
}

.slider_wrap {
  margin-left: 20px;
  margin-right: 20px;
}
.test {
  position: relative;
  width: 100%;
  height: 10px;
}
.duration {
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
  color: gray;
}

</style>
