<template>
  <div>
    <loading :active.sync="showLoading" :can-cancel="false" :is-full-page="true"></loading>

    <div v-if="isLoaded" class="boards etc" style="margin: 0 20px">
      <ul>
        <li v-for="lesson in showLessons" v-bind:key="lesson.id">
          <a :href="'/sc/detail/?id=' + lesson.id" role="button">
            <div class="group">
              <div class="con">
                <p class="tit">{{ lesson.name }}</p>
              </div>
            </div>
            <div class="thumb">
              <img :src="lesson.sharedImage" alt="" style="width: 100%" />
            </div>
          </a>
        </li>
      </ul>

      <!-- <button type="button" class="btn-more" @click="showMore" v-show="showMoreButton">
        <span>더보기</span>
      </button> -->
    </div>
    <!-- <div class="btns" style="margin: 20px;">
      <a
        href="#"
        type="button"
        class="btn-line1 medium"
        @click="showMore"
        v-show="showMoreButton"
      >
        더보기
      </a>
    </div> -->

    <div v-if="isLoaded"></div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'MainView',
  components: {
    Loading,
  },
  computed: {
    showLoading() {
      return this.lessons.length !== this.showLessons.length;
    },
    isLoaded() {
      return this.lessons.length === this.showLessons.length;
    },
    showMoreButton() {
      return this.showLessons.length !== this.contents.length;
    },
  },
  async created() {
    const apiKey = window.API_KEY;
    var options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        // 'content-type': 'application/x-www-form-urlencoded',
        // Authorization:
        // 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyRGF0YSI6eyJpZCI6NzQsInN0YXR1cyI6MCwiZW1haWwiOiJkZXZlbG9wZXJAa29ra2lyaS5rciIsImZ1bGxuYW1lIjoi6rCc67CcIO2FjOyKpO2KuCJ9LCJpYXQiOjE2NDk2OTE1NzN9.V8aBOvCOXSqbS4UTB_xhmjiJVLec7159XebK-yOWLEI',
        // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyRGF0YSI6eyJpZCI6NjEsInN0YXR1cyI6MCwiZW1haWwiOiJ5b25nc2FuZy4yQGtva2tpcmkua3IiLCJmdWxsbmFtZSI6IndlYi1wbGF5ZXIifSwiaWF0IjoxNjA3MDgxOTkwfQ.UPjuNX_TQB0hSiY8NWSbeb-B11K506YTOrixHz8e7jo',
      },
      method: 'GET',
      // url: "https://api-production.kokkiri.kr/" + "api/lesson/getDetails",
      url: `https://api-production.kokkiri.kr/api/miniapp/get-list?apiKey=${apiKey}`,
      data: {},
    };

    const result = await this.axios(options);
    let data = result.data.data;

    // for (let i = 0; i < this.contents.length; i++) {
    // for (let i = 0; i < 3; i++) {
    //   let id = this.contents[i];

    //   options.data = { id };
    //   let result = await this.axios(options);

    //   let data = result.data.data;
    //   data.name = this.titles[i];
    //   this.lessons.push(data);
    // }

    this.lessons = data;
    // console.log(this.lessons);
    // this.showMore();
    this.showLessons = this.lessons;
  },
  async mounted() {},
  data() {
    return {
      contents: [
        782, 1008, 817, 824, 745, 924, 876, 709, 826, 823, 545, 438, 433, 710, 688, 809, 690, 395,
        779, 838,
      ],
      titles: [
        '긴장을 녹이는 바디스캔 명상',
        '하루 시작 전 축복 명상',
        '점심시간 행복 휴식 명상 ',
        '일터에서 힘들 때 명상 ',
        '불안한 생각에 매달리지 않기 명상',
        '잠들기 전 무의식 정화 명상',
        '깊은 수면 심상화 호흡 명상',
        '잠으로 떠나는 발리섬 여행 명상',
        '잠으로 안내하는 구름 휴식 명상',
        '잠으로 안내하는 촛불 이완 명상 ',
        '마음을 여는 아침새소리 ASMR ',
        '스트레스를 날려주는 대나무숲 ASMR ',
        '머리가 맑아지는 시냇물 소리 ASMR ',
        '잠시 현실을 떠나 세도나의 사막 ASMR ',
        '바다가 그리운 날 파도와 갈매기 소리 ASMR ',
        '숲속 캠핑 음악 ASMR ',
        '파리 몽마르뜨 카페 ASMR ',
        '피노키오 동화',
        '나의 라임 오렌지 나무 동화',
        '미운 오리 새끼 동화',
      ],
      lessons: [],
      showLessons: [],
    };
  },
  methods: {
    async showMore() {
      // console.log('showMore');
      // var options = {
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json;charset=UTF-8',
      //     // 'content-type': 'application/x-www-form-urlencoded',
      //     //   Authorization:
      //     //     // 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyRGF0YSI6eyJpZCI6NzQsInN0YXR1cyI6MCwiZW1haWwiOiJkZXZlbG9wZXJAa29ra2lyaS5rciIsImZ1bGxuYW1lIjoi6rCc67CcIO2FjOyKpO2KuCJ9LCJpYXQiOjE2NDk2OTE1NzN9.V8aBOvCOXSqbS4UTB_xhmjiJVLec7159XebK-yOWLEI',
      //     //     'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyRGF0YSI6eyJpZCI6NjEsInN0YXR1cyI6MCwiZW1haWwiOiJ5b25nc2FuZy4yQGtva2tpcmkua3IiLCJmdWxsbmFtZSI6IndlYi1wbGF5ZXIifSwiaWF0IjoxNjA3MDgxOTkwfQ.UPjuNX_TQB0hSiY8NWSbeb-B11K506YTOrixHz8e7jo',
      //   },
      //   method: 'GET',
      //   // url: "https://api-production.kokkiri.kr/" + "api/lesson/getDetails",
      //   url: `https://api-production.kokkiri.kr/api/miniapp/get-list?apiKey=${apiKey}`,
      //   data: {},
      // };
      // let len = this.showLessons.length;
      // const result = await this.axios(options);
      // let data = result.data.data;
      // this.lessons = data;
      // this.showLessons = this.lessons;
      // let start = this.showLessons.length;
      // let addItems = this.lessons.slice(start, start + 3);
      // this.showLessons = [...this.showLessons, ...addItems];
    },
  },
};
</script>

<style>
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
@import url('../assets/resources/include/css/base.css');
@import url('../assets/resources/include/css/layout.css');
@import url('../assets/resources/include/css/style.css');
</style>
